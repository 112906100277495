<template>
  <a v-if="position.fixed_qty && +position.fixed_qty"
     @click.prevent="getInvoicesData($event, position.id)"
     href="#"
     class="p-link table-link-icon">
    <i class="ti-link"></i>
    <span style="font-size: 0.9em; position: relative; top: -1px;" class="p-ml-1">{{ +position.fixed_qty }}</span>
  </a>
  <OverlayPanel :ref="'ppio-' + position.id">
    <Spinner v-if="isLoading" :isSmall="true" :spinnerStyle="'height:60px'"/>
    <div v-else class="overlay-panel-inner">
      <div v-if="purchaseInvoices?.length">
        <div v-for="(invoice, index) of purchaseInvoices" :key="invoice.id">
          <div v-if="showLink">
            <router-link v-if="invoice.number"
                         :to="{ path: '/purchase-invoices', query: { search: invoice.number } }"
                         target="_blank">
              <span>{{ invoice.number }}</span>
            </router-link>
          </div>
          <div v-else>
            <span>{{ invoice.number }}</span>
          </div>
          <!--        <div>{{ $t('Created at') }}: {{ formatDate(invoice.created_at) }}</div>-->
          <hr v-show="index < purchaseInvoices.length - 1">
        </div>
      </div>
      <div v-else>{{ $t('No data available') }}</div>
    </div>
  </OverlayPanel>
</template>

<script>
import Spinner from "@/components/Spinner";
import httpClient from "@/services/http.services";
import constants from "@/constants";
// import httpMixins from "@/mixins/httpMixins";
import showErrorsMixins from "@/mixins/showErrorsMixins";

export default {
  name: 'PositionFixedQtyButton',
  mixins: [showErrorsMixins],
  components: { Spinner },
  props: {
    position: Object,
    isReducedPosition: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: false,
      purchaseInvoices: null,
    }
  },
  methods: {
    async getInvoicesData($event, positionId) {
      this.togglePositionPurchaseInvoicesOverlay($event, positionId)

      if (!this.$refs['ppio-' + this.position.id.toString()].visible) return false

      this.isLoading = true

      const apiLink = this.isReducedPosition ? 'purchase-invoice/get-reduced-position-invoices' : 'purchase-invoice/get-request-position-invoices'

      try {
        const { data, status } = await httpClient.post(apiLink, { positionId })
        if (status === 200 && data) {
          this.purchaseInvoices = data
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.isLoading = false
      }
    },
    togglePositionPurchaseInvoicesOverlay(event, id) {
      if (!event || !id) {
        return false
      }

      this.$refs['ppio-' + id.toString()].toggle(event);
    },
  },
  computed: {
    showLink() {
      return !!(this.$store.state.user.role === constants.userRoles.superAdmin ||
          this.$store.state.user.role === constants.userRoles.admin ||
          this.$store.state.user.role === constants.userRoles.accountant)
    }
  }
  // methods: {
  //   calculateFixedQty(position) {
  //     const fixedQty = +position.fixed_qty > +position.qty ? +position.qty : +position.fixed_qty
  //     return fixedQty
  //   }
  // }
}
</script>

<style lang="scss" scoped>
.overlay-panel-inner {
  min-width: 110px!important;
}
</style>