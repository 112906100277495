export default [
    {
        num: 1,
        label: {
            en: 'New',
            et: 'Uus',
            ru: 'Новый'
        },
        // icon:'request-state request-state--new',
        classData: 'request-state--new'
    },
    {
        num: 3,
        label: {
            en: 'Need to order',
            et: 'On vaja tellida',
            ru: 'Нужно заказать'
        },
        // icon:'request-state request-state--to-order',
        classData: 'request-state--to-order'
    },
    {
        num: 2,
        label: {
            en: 'Сanceled',
            et: 'Tühistatud',
            ru: 'Отменено'
        },
        // icon:'request-state request-state--to-order',
        classData: 'request-state--сanceled'
    },
    {
        num: 4,
        label: {
            en: 'Ordered',
            et: 'Tellitud',
            ru: 'Заказано'
        },
        // icon:'request-state request-state--ordered',
        classData: 'request-state--ordered'
    },
    {
        num: 5,
        label: {
            en: 'Waiting for delivery',
            et: 'Ootab kohaletoimetamist',
            ru: 'Ожидает доставки'
        },
        // icon:'request-state request-state--waiting-delivery',
        classData: 'request-state--waiting-delivery'
    },
    {
        num: 6,
        label: {
            en: 'Need to deliver',
            et: 'Vaja kohale toimetada',
            ru: 'Нужно доставить'
        },
        // icon:'request-state request-state--need-to-deliver',
        classData: 'request-state--need-to-deliver'
    },
    {
        num: 7,
        label: {
            en: 'Delivered',
            et: 'Tarnitud',
            ru: 'Доставлено'
        },
        // icon:'request-state request-state--delivered',
        classData: 'request-state--delivered'
    }
]